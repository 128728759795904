'use client'
import { Body } from "@/components/wysiwyg/Body";
import { searchResultHit } from "../../../lib/search/server-actions.server";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import classNames from "classnames";
import { sendGTMEvent } from '@next/third-parties/google'

export const SearchResult = ({href, highlight, sourceName, objName}: searchResultHit) => {
  const pathname = usePathname()
  const router = useRouter()

  // const summaryFieldName = [sourceName, objName, 'summary'].join('___') + '.en';
  const Topic = highlight['Topic'] || undefined

  // Hack to make production links relative.
  const rel = href.startsWith('https://docs.acquia.com/') ? href.replace('https://docs.acquia.com', '') : href

  const isActive = rel == pathname
  const target = rel.startsWith('http') ? '_blank' : undefined

  const goto = () => {
    sendGTMEvent({
      event: 'searchResultSelect',
      value: rel
    })
    router.push(rel)
  }
  
  return (
    <div onClick={goto} className={classNames("mb-2 p-4 rounded hover:cursor-pointer hover:bg-gray-400", isActive ? 'bg-gray-400 border-gray-600 border' : '')}>
      <h2 className="font-display text-lg mb-1 text-navy-600">
        <Link target={target} href={rel} className="">
          {highlight.TitleToDisplayString}
        </Link>
      </h2>
      <div>
        <Body value={highlight.SummaryToDisplay[0]} />
      </div>
      <p className="italic text-blue-800 underline text-sm my-1"><Link href={rel} target={target}>{href}</Link></p>
    </div>
  )
}
