'use client'

import { useEffect, useState } from "react"
import { SearchFilters, search, searchResults } from "../../../lib/search/server-actions.server"
import { SearchResult } from "./SearchResult.client"
import { SURelatedResource } from "./SURelatedResource"

export const SUResults = ({query, filters, children, resultType = 'SearchResult'}: {
  query?: string
  filters?: SearchFilters
  resultType?: 'SearchResult' | 'SURelatedResource'
  children?: React.FC<{
    isWorking: boolean
    searchResults?: searchResults
  }>
}) =>  {
  const [searchResults, setSearchResults] = useState<searchResults>()
  const [isWorking, setIsWorking] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>()
  const [searchFilters, setSearchFilters] = useState<SearchFilters>()
  
  useEffect(() => {
    if (!searchQuery) {
      return
    }

    // Initiate the search (server action).
    search(searchQuery, searchFilters).then((results) => {
      // Set the search results.
      setSearchResults(results)
      setIsWorking(false)
    })

    setIsWorking(true)
    // Prevent further searches to protect the API from search stampedes.
  }, [searchQuery, searchFilters])

  if (searchQuery != query) {
    setSearchQuery(query)
  }

  // Have to convert filters to a string because javascript
  // will always consider the objects as not-equal.
  if (JSON.stringify(searchFilters) != JSON.stringify(filters)) {
    setSearchFilters(filters)
  }

  return <>
    {!isWorking && query !== undefined && searchResults?.data.result.hits.map(hit => resultType != 'SURelatedResource' ? <SearchResult key={hit._id} {...hit} /> : <SURelatedResource key={hit._id} {...hit} />)}
    {children != undefined && children({isWorking, searchResults})}
  </>
}