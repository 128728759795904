import classNames from "classnames"
import { ReactNode } from "react"

export interface StatusMessageProps {
    heading?: string
    children?: ReactNode
    type: "note" | "important" | "caution" | "warning" | "advisory" | "alert"
    className?: string
}

export type StatusMessageTagName = 'h2' | 'h3' | 'h4' | 'div'

export const StatusMessage = ({heading, children, type, className}: StatusMessageProps) => {
    return (
        <div className={classNames("widget-status-message", type, className)}>
            <div className="icon"></div>
            <div>
                { heading && (
                    // To support old status messages
                    <div className="widget-status-message-title">{heading}</div>
                )}
                {children}
            </div>
        </div>
    )
}