'use client'
import { LinkType, PreviewBarContextProps, usePreviewBar } from "@/contexts/PreviewBarProvider"
import { MinusIcon, PlusIcon, XMarkIcon } from "@heroicons/react/20/solid"
import { usePathname } from "next/navigation"
import { ReactNode, useEffect, useState } from "react"
import { Body } from "../../wysiwyg/Body"
import { DrupalEntityWithJiraReference } from "@/types"
import { DocumentIcon, PencilSquareIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline"
import classNames from "classnames"

type PreviewState = NonNullable<PreviewBarContextProps["preview"]["state"]>

const state_message_map:Record<PreviewState, string> = {
  published: 'Published',
  draft: 'Draft',
  archived: 'Archived',
  code: 'In code',
  loading: 'Loading...'
}

export const PreviewBar = ({}:{}) => {
  const pathname = usePathname()
  const {preview, reset, enabled} = usePreviewBar()
  const [inIframe, setInIframe] = useState(true)
  const [path, setPath] = useState(pathname)

  useEffect(() =>  {
    setInIframe(typeof window !== "undefined" && window.self != window.top)
  }, [])

  useEffect(() => {
    setPath(pathname)
  }, [pathname])

  useEffect(() => {
    if (path != pathname) {
      reset()
    }
  }, [path, pathname, reset])


  const highlight = classNames(
    preview.mode == 'current' ? 'bg-teal-400' : (
      preview.mode == 'diff' ? 'bg-gray-400' : (
        preview.mode == 'revision' ? 'bg-yellow-400' : 'bg-gray-400')))

  const subtitle = preview.mode == 'diff' ? 'Showing diff' : state_message_map[preview.state as PreviewState]

  if (!enabled) {
    return <></>
  }

  return <div className={`sticky top-0 z-10 ${highlight} drop-shadow`}>
       {!inIframe && 
        <a title="Close preview mode" href={'/api/end-preview?slug=' + pathname} 
         className="transition absolute right-0 m-2 hover:bg-white/50 rounded py-2 px-4 flex text-sm">
          <XMarkIcon className='w-4 overflow-visible' />
        </a>} 
        <div className="container mx-auto p-3 flex flex-row gap-3 items-center">
          <div>
            <h2 className="font-display whitespace-nowrap">Preview mode</h2>
            <div className="whitespace-nowrap">{subtitle}</div>
          </div>
          { preview.modes.length > 1 && <ViewModes />}
          
          {/* Only display when preview is embedded inside an iframe. */}
          {!inIframe && preview.links && preview.links.length > 0 && <ViewLinks />}
          <div className="duration-300 transition-all overflow-hidden max-h-12">
            <Body value={preview.resource?.revision_log ?? ''} />
          </div>
      </div>
    </div>
}

const ViewModes = ({children}: {
  children?: ReactNode
}) => {
  const { set, preview: {mode, modes} } = usePreviewBar()

  return <div className="flex gap-1">
    {children}
    {modes.includes('current') && (
      <button 
        onClick={() => set.mode('current')}
        title="View the current published version."
        className={classNames(mode == 'current' ? 'bg-teal-600 text-white' : 'bg-gray-600 hover:bg-teal-600 hover:text-white', "p-2 rounded-lg")}>
          <DocumentIcon className="w-5" />
      </button>
    )}
    {modes.includes('diff') && (
      <button 
        onClick={() => set.mode('diff')}
        title='View differences of changes from published version.'
        className={classNames(mode == 'diff' ? 'bg-orange-600 text-white' : 'bg-gray-600 hover:bg-orange-600 hover:text-white', "p-2 px-3 rounded-lg")}>
          <PlusIcon className="w-3" /><MinusIcon className="w-3" />
      </button>
    )}
    {modes.includes('revision') && (
      <button 
        onClick={() => set.mode('revision')}
        title="View the latest draft revision."
        className={classNames(mode == 'revision' ? 'bg-yellow-600 text-white' : 'bg-gray-600 hover:bg-yellow-600 hover:text-white', "p-2 rounded-lg")}>
          <WrenchScrewdriverIcon className="w-5" />
      </button>
    )}
  </div>
}

const ViewLinks = () => {
  const { preview } = usePreviewBar()
  return <div className="flex gap-1">
    {preview.links?.map(link => <a className={classNames("p-2 whitespace-nowrap rounded-lg bg-navy-600/20", link.className)} key={link.href} href={link.href} title={link.title}>{link.text}</a>)}
  </div>  
}

export const PreviewTag = (props: Omit<PreviewBarContextProps["preview"], "links">) =>  {
  const context = usePreviewBar()

  if (context.preview.mode != props.mode) {
    context.set.mode(props.mode)
  }
  if (context.preview.state != props.state) {
    context.set.state(props.state)
  }
  if (JSON.stringify(context.preview.modes) != JSON.stringify(props.modes)) {
    context.set.modes(props.modes)
  }

  if (JSON.stringify(context.preview.resource) != JSON.stringify(props.resource)) {
    context.set.resource(props.resource)
    context.set.links(buildLinks(props.resource))
  }
  return <></>
}

function buildLinks(node?: DrupalEntityWithJiraReference) {
  const links:LinkType[] = []

  if (!node) {
    return links
  }

  links.push({
    href: '/api/edit?nid=' + node.drupal_internal__nid,
    title: 'Go the page edit URL',
    text: <PencilSquareIcon className="w-5" />,
    className: 'hover:bg-orange-600 hover:text-white'
  })

  const jira_issue:string|false = node.field_jira_doc_issue?.id ?? node.field_jira_kb_issue?.id ?? false

  if (jira_issue) {
    links.push({
      href: `https://acquia.atlassian.net/browse/${jira_issue}`,
      title: `Discuss on Jira ticket ${jira_issue}`,
      text: jira_issue,
      className: 'hover:bg-blue-600 hover:text-white'
    })
  }

  return links
}

// const [params, setParams] = useState<PreviewBarProps|null>(null)

// useEffect(() => {
//   const getParams = () => {
//     getPreviewModeData(pathname).then((params) => {
//       setParams(params)
//     })
//   }

//   if (isEnabled) {
//     getParams()
//   }
// }, [isEnabled, pathname])

// if (!isEnabled) {
//   return (
//     <PreviewBarContext.Provider value={null}>
//       {children}
//     </PreviewBarContext.Provider>
//   )
// }


// export function PreviewBarRender({links, hasDiff, state, log, diffMode, session, children}: PreviewBarProps & {
// children: ReactNode
// }) {
// const [inIframe, setInIframe] = useState(true)
// const [mode, setMode] = useState(diffMode)
// const [toggleState, setToggleState] = useState(false)


// const pathname = usePathname()
// const toggleModes:stateOptions["modes"][] = ['revision', 'diff', 'current']

// function getViewMode(): stateOptions["modes"] {
//   return toggleModes[mode % toggleModes.length]
// }

// const higlightMap:{ 
//   [P in PreviewBarProps["state"]]?: string 
// } = {
//   published: 'bg-teal-400',
//   draft: 'bg-yellow-400',
// }

// const highlight = (state.length && Object.keys(higlightMap).indexOf(state) !== -1)?
// higlightMap[state] : 'bg-gray-400'

// const diffHighlightMap:Record<stateOptions["modes"], string> = {
//   revision: 'orange',
//   diff: 'pink',
//   current: 'teal'
// };

//  // Registering classnames with Tailwind.
// [
//   'peer-checked:bg-teal-600', 'bg-teal-600', 'ring-teal-300',
//   'peer-checked:bg-pink-600', 'bg-pink-600', 'ring-pink-300',
//   'peer-checked:bg-orange-600', 'bg-orange-600', 'ring-orange-300',
// ]

// const diffHlTag = 'bg-' + diffHighlightMap[getViewMode()] + '-600';
// const diffHighlight = [ toggleState ? 'peer-checked:' + diffHlTag : diffHlTag]
// diffHighlight.push('ring-' + diffHighlightMap[getViewMode()] + '-300')


// return (
//   <>
//     <div className={`sticky top-0 z-10 ${highlight} drop-shadow`}>
 
      

//     </div>
//     <PreviewBarContext.Provider value={getViewMode()}>
//       {children}
//     </PreviewBarContext.Provider>
//   </>
// )
// }