import { Body } from "@/components/wysiwyg/Body";
import { searchResultHit } from "../../../lib/search/server-actions.server";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import classNames from "classnames";

export const SURelatedResource = ({href, highlight, sourceName, objName}: searchResultHit) => {
  const pathname = usePathname()
  const router = useRouter()

  // const summaryFieldName = [sourceName, objName, 'summary'].join('___') + '.en';
  const Topic = highlight['Topic'] || undefined

  // Hack to make production links relative.
  const rel = href.startsWith('https://docs.acquia.com/') ? href.replace('https://docs.acquia.com', '') : href

  const isActive = rel == pathname
  
  return (
    <Link className="hover:bg-gray-500 p-2 font-bold rounded-lg block" href={rel}>{highlight.TitleToDisplayString}</Link>
  )
}