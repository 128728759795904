'use client'

import hljs, { LanguageFn } from 'highlight.js';
import 'highlight.js/styles/github-dark.css';

import bash from 'highlight.js/lib/languages/bash';
import css from 'highlight.js/lib/languages/css';
import diff from 'highlight.js/lib/languages/diff';
import json from 'highlight.js/lib/languages/json';
import javascript from 'highlight.js/lib/languages/javascript';
import php from 'highlight.js/lib/languages/php';
import python from 'highlight.js/lib/languages/python';
import ruby from 'highlight.js/lib/languages/ruby';
import typescript from 'highlight.js/lib/languages/typescript';
import xml from 'highlight.js/lib/languages/xml';
import yaml from 'highlight.js/lib/languages/yaml';
import plaintext from 'highlight.js/lib/languages/plaintext';
import { ReactNode } from 'react';
import classNames from 'classnames';

export const highlightLanguages = {
    bash, css, diff, json, javascript, php, python, ruby, xml, yaml, typescript, 
    html: xml,
    plaintext
}

export interface CodeHighlightProps {
  language: keyof typeof highlightLanguages
  code?: string
  children?: string
  className?: string
}

export const CodeHighlight = ({children, language, code, className}: CodeHighlightProps) => {

  if (code === undefined && children === undefined) {
    throw new Error("code or children required in CodeHighlight component.")
  }

  if (highlightLanguages[language] == undefined) {
    return;
  }
  hljs.registerLanguage(language, highlightLanguages[language]);

  return (
    <div className='relative'>
      <code 
        className={classNames(className, `hljs ${language}`)} 
        data-lang={language} 
        dangerouslySetInnerHTML={{__html: hljs.highlight(code ?? children ?? '', {language}).value}} />
    </div>
    
  )
}
  